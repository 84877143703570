import { createSlice } from '@reduxjs/toolkit'


const initialStateStockNoti = {
    SelectStore: null,
    SelectProject: null,
    ItemId: null
  }
export const StockNoti = createSlice({
    name: 'StockNoti',
    initialState: initialStateStockNoti,
    reducers: {
        handleClicktoStore: (state, action) => {
            state.SelectStore = { ...action.payload.SelectStore, name: action.payload.SelectStore.label }
            state.SelectProject =  action.payload.SelectProject
            state.ItemId = action.payload.ItemId
          },
        handleClearStockNoti: (state)  => { 
            state.SelectStore = null
            state.SelectProject = null
            state.ItemId = null
        }
    }
})


export const { handleClicktoStore, handleClearStockNoti } = StockNoti.actions

export default StockNoti.reducer