// ** Redux Imports
import { createSlice } from '@reduxjs/toolkit'

export const StoreProject = createSlice({
    name: 'StoreProject',
    initialState: {
      SelectStore: window.localStorage.getItem('SelectStore'),
      SelectProject: window.localStorage.getItem('SelectProject')
    },
    reducers: {
        handleSeleteStore: (state, action) => {
            state.SelectStore = action.payload
           localStorage.setItem('SelectStore',  JSON.stringify(action.payload))
          },
          handleSeleteProject: (state, action) => {
            state.SelectProject = action.payload
            localStorage.setItem('SelectProject',  JSON.stringify(action.payload))
          },
          handleClear: ()  => {
            localStorage.removeItem('SelectProject')
            localStorage.removeItem('SelectStore')
          }
    }
})

export const { handleSeleteStore, handleSeleteProject, handleClear } = StoreProject.actions

export default StoreProject.reducer