// ** Auth Endpoints
export default {
  loginEndpoint: '/api/Auth/Login',
  registerEndpoint: '/api/Auth/register',
  refreshEndpoint: '/api/Auth/refresh-token',
  logoutEndpoint: '/api/Auth/logout',

  // ** This will be prefixed in authorization header with token
  // ? e.g. Authorization: Bearer <token>
  tokenType: 'Bearer ',

  // ** Value of this property will be used as key to store JWT token in storage
  storageTokenKeyName: 'accessToken',
  storageRefreshTokenKeyName: 'refreshToken'
}
