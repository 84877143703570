import axios from 'axios'

const DevMode = process.env.REACT_APP_TEST
const REACT_APP_API = process.env.REACT_APP_API
const REACT_APP_API_PRD = process.env.REACT_APP_API_PRD

const BASEURL = DevMode === 'true'  ?    REACT_APP_API : REACT_APP_API_PRD

export const SupalaiAPI = axios.create({
    baseURL: BASEURL,
    timeout: 3000
  })

export const EnvMode = DevMode 