// ** Reducers Imports
import navbar from './navbar'
import layout from './layout'
import auth from './authentication'
import StoreProject from './StoreProject'
import StockNoti from './stocknoti'
const rootReducer = {
  auth,
  navbar,
  layout,
  StoreProject,
  StockNoti
}

export default rootReducer
